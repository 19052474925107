<template>
  <div class="EditCard">
    <AppBarLayout
      class="EditCard__app-bar"
      action-buttons-class="ml-10"
    >
      <template #actions>
        <div class="EditCard__app-bar-actions">
          <v-btn
            color="primary"
            depressed
            @click="submit"
          >
            {{ $t('dashboard.SaveCard') }}
          </v-btn>

          <v-btn
            color="primary"
            depressed
            outlined
            :to="backRoute"
            replace
            exact
            class="ml-2"
          >
            {{ $t('dashboard.Cancel') }}
          </v-btn>
        </div>
      </template>
    </AppBarLayout>

    <div class="EditCard__content">
      <div class="EditCard__left-column">
        <h1
          class="EditCard__left-title"
          v-text="$t('dashboard.WhatToTrackQ')"
        />

        <!-- Radio-btns with card type selection and some extra selects
             (based on the card type) -->
        <v-radio-group
          v-model="model.track"
          class="EditCard__track-radio-group"
        >
          <v-radio
            :value="TRACK.number"
            :label="$t('dashboard.OptionToTrackNumber')"
            class="EditCard__track-radio"
          />

          <v-radio
            :value="TRACK.subset"
            :label="$t('dashboard.OptionToTrackSubset')"
            class="EditCard__track-radio"
          />

          <v-radio
            v-if="projectId && checklists && checklists.length"
            :value="TRACK.checklistStatus"
            :label="$t('dashboard.OptionToTrackChecklist')"
            class="EditCard__track-radio"
          />

          <v-radio
            v-if="projectId != null"
            :value="TRACK.params"
            :label="$t('dashboard.OptionToTrackParameters')"
            class="EditCard__track-radio"
          />

          <v-expand-transition>
            <!-- Select prop for a pie-chart or two axes of a table -->
            <div
              v-if="model.track === TRACK.params"
              class="EditCard__expansion-panel"
            >
              <div>
                <div
                  class="EditCard__label"
                  v-text="$t('dashboard.ParameterFirst')"
                />
                <v-radio-group
                  v-model="model.firstParam"
                  class="EditCard__param-radio-group mr-12"
                >
                  <v-radio
                    :key="RADIO_NONE"
                    :value="RADIO_NONE"
                    :label="$t('dashboard.None')"
                    class="EditCard__param-radio"
                  />
                  <v-radio
                    v-for="item in GROUP_BY_FIELDS"
                    :key="item.value"
                    :value="item.value"
                    :label="item.text"
                    class="EditCard__param-radio"
                  />
                </v-radio-group>
              </div>

              <v-expand-x-transition>
                <div v-show="!!model.firstParam">
                  <div
                    class="EditCard__label"
                    v-text="$t('dashboard.ParameterSecond')"
                  />
                  <v-radio-group
                    v-model="model.secondParam"
                    class="EditCard__param-radio-group"
                  >
                    <v-radio
                      :key="RADIO_NONE"
                      :value="RADIO_NONE"
                      :label="$t('dashboard.ParameterNone')"
                      class="EditCard__param-radio"
                    />
                    <v-radio
                      v-for="item in GROUP_BY_FIELDS"
                      v-show="item.value !== model.firstParam"
                      :key="item.value"
                      :value="item.value"
                      :label="item.text"
                      class="EditCard__param-radio"
                      style="white-space: nowrap"
                    />
                  </v-radio-group>
                </div>
              </v-expand-x-transition>
            </div>
          </v-expand-transition>
        </v-radio-group>
      </div>

      <v-form
        v-show="!!cardType"
        ref="form"
        class="EditCard__right-column"
        @submit.prevent
      >
        <div class="EditCard__card-area">
          <v-text-field
            ref="nameInput"
            :value="model.name"
            :placeholder="$t('dashboard.CardTitle')"
            class="EditCard__name"
            name="name"
            required
            maxlength="45"
            :rules="[(validate && !model.name.trim()) ?$t('dashboard.NameRequired') : true]"
            :disabled="saving"
            @input="!model.name.trim() && (model.name = $event)"
            @change="model.name = $event"
            @focus="$refs.nameInput.$el.querySelector('input').select()"
            @keydown.enter="$refs.nameInput.blur()"
          />

          <!-- Disabled/readonly card preview -->
          <DashboardCard
            v-if="previewCard != null"
            :card="previewCard"
            :project-id="projectId"
            disabled
            preview
            class="EditCard__card-preview"
            :style="{
              minHeight: {
                [CARD_TYPE.pieChart]: '266px',
              }[previewCard.cardType] || null
            }"
          />

          <!-- Color selector for the card -->
          <ColorPicker
            v-show="!!cardType &&
              cardType !== CARD_TYPE.pieChart &&
              cardType !== CARD_TYPE.table &&
              cardType !== CARD_TYPE.checklistStatus"
            v-model="model.metaData.color"
            expanded
            class="EditCard__color-picker"
          />
        </div>
      </v-form>

      <SecondaryFiltersDrawer
        v-if="cardType === CARD_TYPE.aOfB || cardType === CARD_TYPE.checklistStatus"
        v-model="model.secondaryFilter"
        class="EditCard__pie-filters"
        :project-id="projectId"
        :checklist-id="checklistId"
        :base-filter="filter"
      />
    </div>
  </div>
</template>

<script>
import * as R from 'ramda'

import { BG, COLORS, CARD_TYPE } from '../constants'
import { objectToIssueFilterQuery, reportError, replaceRoute, randomChoice, issueFilterQueryToObject } from '../helpers'

import Dashboard from '../store/orm/dashboard'
import DashboardCard from '../store/orm/dashboardCard'
import Issue from '../store/orm/issue'
import ProjectGroup from '../store/orm/projectGroup'

import ColorPicker from '../components/ColorPicker'
import DashboardCardComponent from '../components/DashboardCard'
import SecondaryFiltersDrawer from '../components/SecondaryFiltersDrawer'
import AppBarLayout from '../layouts/AppBarLayout'
import i18n from '../i18n'

const freeze = Object.freeze.bind(Object)

const RADIO_NONE = 0
const TRACK = freeze({
  number: CARD_TYPE.counter,
  subset: CARD_TYPE.aOfB,
  params: `${CARD_TYPE.pieChart}/${CARD_TYPE.table}`,
  checklistStatus: CARD_TYPE.checklistStatus,
})
const CARD_TYPE_TO_TRACK = freeze({
  [CARD_TYPE.counter]: TRACK.number,
  [CARD_TYPE.aOfB]: TRACK.subset,
  [CARD_TYPE.pieChart]: TRACK.params,
  [CARD_TYPE.table]: TRACK.params,
  [CARD_TYPE.checklistStatus]: TRACK.checklistStatus,
})

const extractFirstParam = ({ cardType, query }) => {
  switch (cardType) {
    case CARD_TYPE.pieChart: return query.groupBy
    case CARD_TYPE.table: return query.xAxis
    default: return RADIO_NONE
  }
}
const extractSecondParam = ({ cardType, query }) => {
  switch (cardType) {
    case CARD_TYPE.table: return query.yAxis
    default: return RADIO_NONE
  }
}
const initModel = baseCard => baseCard
  ? {
    track: CARD_TYPE_TO_TRACK[baseCard.cardType],
    firstParam: extractFirstParam(baseCard),
    secondParam: extractSecondParam(baseCard),
    name: baseCard.name,
    secondaryFilter: baseCard.cardType === CARD_TYPE.aOfB
      ? issueFilterQueryToObject(baseCard.query.subQuery)
      : {},
    metaData: {
      pattern: randomChoice(Object.keys(BG)),
      color: randomChoice(COLORS),
      ...baseCard.metaData,
    },
  }
  : {
    track: TRACK.number,
    firstParam: RADIO_NONE,
    secondParam: RADIO_NONE, // for table
    name: i18n.t('dashboard.NewCard'),
    secondaryFilter: {},
    metaData: {
      pattern: randomChoice(Object.keys(BG)),
      color: randomChoice(COLORS),
    },
  }

export default {
  name: 'EditCard',

  components: {
    AppBarLayout,
    ColorPicker,
    DashboardCard: DashboardCardComponent,
    SecondaryFiltersDrawer,
  },

  metaInfo() {
    return {
      title: this.$store.getters.title(this.$t('dashboard.ConfigureCard')),
    }
  },

  props: {
    projectId: { type: String, default: null },

    checklistId: { type: String, default: null },
    // e.g.: { totalScore: '1,2', ... }
    filter: { type: Object, default: null },

    cardId: { type: Number, default: null },
    back: { type: String, default: null },

    // for copying existing card ("Save as" btn)
    forceCreate: { type: Boolean, default: false },
  },

  data() {
    return {
      TRACK,
      CARD_TYPE,
      RADIO_NONE,
      GROUP_BY_FIELDS: freeze([
        freeze({ text: this.$t('dashboard.Status'), value: 'status' }),
        freeze({ text: this.$t('dashboard.TotalScore'), value: 'totalScore' }),
        freeze({ text: this.$t('dashboard.Criticality'), value: 'criticalityScore' }),
        freeze({ text: this.$t('dashboard.Probability'), value: 'probabilityScore' }),
      ]),

      model: initModel(),
      previewValue: undefined,
      saving: false,
      validate: false,
    }
  },

  computed: {
    dashboardId() { return this.projectId || Dashboard.GLOBAL_ID },

    checklists() { return this.$store.state.checklist.checklists },

    checklistById() {
      const { $store, checklistId } = this
      return $store.state.checklist.byId[checklistId]
    },

    dashboardRoute() {
      const { projectId } = this
      return projectId
        ? {
          name: 'ProjectDashboard',
          params: { projectId },
        }
        : { name: 'Dashboard' }
    },

    backRoute() {
      return this.back ?? this.dashboardRoute
    },

    baseCard() {
      const { cardId } = this
      return DashboardCard.find(cardId)
    },

    cardBaseQuery() {
      return objectToIssueFilterQuery(this.filter)
    },

    cardType() {
      const { track, firstParam, secondParam } = this.model

      const type = {
        [TRACK.number]: CARD_TYPE.counter,
        [TRACK.subset]: CARD_TYPE.aOfB,
      }[track]

      if (this.checklistId) return TRACK.checklistStatus

      if (type != null) return track

      if (track === TRACK.params) {
        if (firstParam && secondParam) return CARD_TYPE.table
        if (firstParam) return CARD_TYPE.pieChart
      }

      return null
    },

    cardQuery() {
      const {
        cardType,
        cardBaseQuery,
        model: { firstParam, secondParam, secondaryFilter },
      } = this

      if (cardType === CARD_TYPE.counter) {
        return { query: cardBaseQuery }
      } else if (cardType === CARD_TYPE.checklistStatus) {
        return {
          nodeID: this.checklistId,
        }
      } else if (cardType === CARD_TYPE.aOfB) {
        return {
          mainQuery: cardBaseQuery,
          subQuery: objectToIssueFilterQuery(secondaryFilter),
        }
      } else if (cardType === CARD_TYPE.pieChart && firstParam) {
        return {
          query: cardBaseQuery,
          groupBy: firstParam,
        }
      } else if (cardType === CARD_TYPE.table && firstParam && secondParam) {
        return {
          query: cardBaseQuery,
          xAxis: firstParam,
          yAxis: secondParam,
        }
      }

      return null
    },

    previewCard() {
      const { cardType, cardQuery, model: { name, metaData }, previewValue } = this

      return cardType && {
        cardType,
        name,
        query: cardQuery,
        value: previewValue,
        metaData,
      }
    },
  },

  watch: {
    projectId: {
      immediate: true,
      async handler(projectId) {
        const { $store } = this
        if (projectId) {
          await Promise.all([
            $store.dispatch('issueSchema/get', { projectId, reload: false }),
            $store.dispatch('checklist/getChecklist', { projectId }),
          ])
        }
      },
    },

    cardId: {
      immediate: true,
      handler(cardId) {
        const { dashboardId } = this
        cardId && DashboardCard.dispatch('$getDetails', { dashboardId, cardId })
      },
    },

    baseCard: {
      immediate: true,
      handler(card, prevCard) {
        this.model = initModel(card)

        if (this.checklistId) {
          this.model.track = TRACK.checklistStatus
        }
        if (card && card.id !== prevCard?.id) {
          this.$router.replace({
            ...this.$route,
            query: {
              ...this.$route.query,
              ...issueFilterQueryToObject(card.type === CARD_TYPE.aOfB
                ? card.query.mainQuery
                : card.query.query),
            },
          }).catch(() => {})
        }
      },
    },

    filter: {
      handler(filter) {
        const { projectId } = this
        Issue.commit(({ $filter }) => this.$set($filter, projectId || null, R.clone(filter)))
      },
      immediate: true,
    },

    'model.firstParam'(firstParam) {
      if (firstParam === this.model.secondParam) {
        this.model.secondParam = this.GROUP_BY_FIELDS
          .find(({ value }) => value !== firstParam).value
      }
    },

    'model.metaData.color'(newColor, oldColor) {
      if (newColor && newColor !== oldColor) {
        this.model.metaData.pattern = randomChoice(Object.keys(BG))
      }
    },

    checklistById: {
      immediate: true,
      handler(checklistById) {
        if (checklistById) {
          this.model.name = checklistById.name || this.checklists[0]?.uuid
          this.model.track = TRACK.checklistStatus
        }
      },
    },

    'model.track': {
      immediate: true,
      handler(track) {
        const { projectId, $route } = this
        let { checklistId } = this

        if (!checklistId && this.checklists) {
          checklistId = this.checklists[0]?.uuid
        }

        const params = projectId ? { projectId } : {}
        const query = {
          ...$route.query,
          checklistId: track === TRACK.checklistStatus
            ? checklistId
            : undefined,
        }

        this.$router.push({
          name: projectId ? 'ProjectEditCard' : 'EditCard',
          params,
          query,
        }).catch((e) => e)
      },
    },
  },

  mounted() {
    const { $store, projectId } = this
    this.subscribeOnModelChange()

    ProjectGroup.dispatch('$getTree', { reload: false })
    Issue.dispatch('$getTotal', { projectId })
    $store.dispatch('$issueStatus/getSharedList')
  },

  methods: {
    subscribeOnModelChange() {
      const unwatch = this.$watch(
        () => [this.cardType, this.cardQuery],
        {
          handler: ([cardType, query]) =>
            cardType && query != null && this.calculateValue(),
          deep: true,
          immediate: true,
        },
      )
      this.$once('hook:beforeDestroy', unwatch)
    },

    calculateValue() {
      const { cardType, cardQuery: query, dashboardId } = this
      this.previewValue = undefined
      DashboardCard.dispatch('$validate', { dashboardId, cardType, query })
        .then(card => { this.previewValue = card.value })
        .catch(e => console.error(e))
    },

    async submit() {
      this.validate = true
      await this.$nextTick()
      if (!this.$refs.form?.validate?.()) return

      this.saving = true
      try {
        if (this.cardId && !this.forceCreate) {
          await this.updateCard()
          replaceRoute(this.$router, this.backRoute)
        } else {
          const createdCard = await this.createCard()
          replaceRoute(this.$router, {
            ...this.dashboardRoute,
            query: { cardId: createdCard.id },
          })
        }
      } catch (e) {
        reportError(e).catch(e => console.error(e))
      } finally {
        this.saving = false
      }
    },

    createCard() {
      const { dashboardId, previewCard: card } = this
      return DashboardCard.dispatch('$create', { dashboardId, card })
    },

    updateCard() {
      const { dashboardId, previewCard: card, cardId } = this
      return DashboardCard.dispatch('$update', {
        dashboardId,
        cardId,
        card,
      })
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../scss/variables'
.EditCard

  &__content
    height: calc(100vh - 56px)

    display: flex
    flex-wrap: nowrap
    align-items: stretch
    position: relative
    background: #fafafc

    @media #{map-get($display-breakpoints, 'md-and-up')}
      height: calc(100vh - 64px)

  &__left-column
    flex: 0 1 auto
    padding: 48px 24px 24px 48px
    overflow: hidden auto
    text-overflow: ellipsis

    @media #{map-get($display-breakpoints, 'lg-and-up')}
      padding-top: 36px
      padding-left: 48px
      min-width: 400px

    @media #{map-get($display-breakpoints, 'xl-only')}
      padding-top: 72px
      padding-left: 137px
      flex-basis: 48%

  &__left-title
    font-weight: 500
    font-size: 24px
    line-height: 130%
    margin-bottom: 30px

    // white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

    @media #{map-get($display-breakpoints, 'lg-and-up')}
      font-size: 30px
      margin-bottom: 36px

    @media #{map-get($display-breakpoints, 'xl-only')}
      font-size: 36px
      margin-bottom: 48px

  &__track-radio-group, &__param-radio-group
    margin-top: 0 !important
    margin-bottom: 0 !important
    padding-top: 0 !important
    padding-bottom: 0 !important

  &__track-radio, &__param-radio
    margin-bottom: 0 !important

    ::v-deep label
      color: #3C3A52 !important

  &__track-radio
    & + &
      margin-top: 24px !important

    ::v-deep label
      font-size: 16px !important
      line-height: 130% !important

      @media #{map-get($display-breakpoints, 'lg-and-up')}
        font-size: 20px !important

      @media #{map-get($display-breakpoints, 'xl-only')}
        font-size: 24px !important

  &__param-radio
    & + &
      margin-top: 8px !important

    ::v-deep label
      font-size: 14px !important
      line-height: 24px !important

  &__expansion-panel
    margin-top: 16px
    padding-left: 0

    display: flex
    flex-wrap: wrap

    @media #{map-get($display-breakpoints, 'lg-and-up')}
      padding-left: 20px

    @media #{map-get($display-breakpoints, 'lg-and-up')}
      padding-left: 40px

  &__label
    font-size: 14px
    line-height: 24px
    margin-bottom: 10px

  &__right-column
    flex: 1 1 52%
    background: #F1F1F6
    display: flex
    flex-direction: column
    align-items: center

    &:before, &::before
      content: ''
      display: block
      flex: 1 1 178px

    &:after, &::after
      content: ''
      display: block
      flex: 1 1 50%

  &__card-area
    display: flex
    flex-direction: column

  &__name
    flex-grow: 0
    min-width: min(100%, 462px)
    margin: 0 0 10px
    padding: 0 !important

    ::v-deep
      input
        font-weight: normal
        font-size: 24px
        line-height: 32px
        max-height: 32px
        margin-bottom: 0
        text-align: center
      .v-input__slot:before
         display: none
      .v-counter
        color: #D3D4D8
        font-style: normal
        font-weight: 500
        font-size: 14px
        line-height: 20px

      .v-input__slot
         margin-bottom: 0

      .v-messages__message
        text-align: center

  &__color-picker
    margin-top: 32px
    align-self: center

  &__pie-filters
    flex: 0 0 360px
    overflow: hidden auto
</style>
